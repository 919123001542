<template>
  <div class="team" v-if="currentRoute">
    <stage stageLabel="Team" :big="true" :title="currentRoute.title.rendered" :image="currentRoute.acf.stageImage.url"/>
    <introduction :subline="$i18n.locale === 'de' ? 'Über uns' : 'About us'" :maintext="currentRoute.acf.description"/>
    <div ref="teamlist" class="team__content">
      <h3 class="team__sectionhead">{{ $i18n.locale === 'de' ? 'Das Team' : 'The Team'}}</h3>
      <team-blocks :team="pagedTeam"/>
      <pagination v-if="getTeamListProps.totalPages > 1" @change-current="setCurrentPage" :currentPage="currentPage" :totalPages="Number(getTeamListProps.totalPages)"/>
      <div class="team__section-bg">
        <h3 class="team__sectionhead">{{currentRoute.acf.more}}</h3>
        <inline-blocks :withMargin="false" :blocks="currentRoute.acf.blocks"/>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Stage from '@/components/stage.vue'
import Introduction from '@/components/introduction.vue'
import InlineBlocks from '@/components/inline-blocks.vue'
import TeamBlocks from '@/components/team-blocks.vue'
import Pagination from '../components/pagination.vue'

import {mapGetters, mapActions} from 'vuex'

export default {
  name: 'Team',
  components: {
    Stage,
    Introduction,
    InlineBlocks,
    TeamBlocks,
    Pagination
  },
  data() {
    return {
        currentPage: 1,
    }
  },
  computed: {
      ...mapGetters([
          'getTeam',
          'getTeamListProps',
          'getFetched',
      ]),
      currentRoute(){
          const getFetched =  this.getFetched[this.$route.name];
          return getFetched;
      },
      pagedTeam() {
        return this.getTeam.slice((this.currentPage - 1) * 10, this.currentPage * 10)
      }
  },
  created(){
      if(!this.currentRoute) {
          this.fetchCurrentPage(this.$route.name);
      }
      if(this.getTeam.length === 0) {
          this.fetchTeam(this.currentPage)
      }
  },
  watch: {
      currentPage( cur, old) {
          if(Number(this.getTeamListProps.totalItems) > old * 10 && this.getTeam.length !== Number(this.getTeamListProps.totalItems)) {
              this.fetchTeam(cur)
          }
          this.scrollTo(this.$refs.teamlist);
      }
  },
  methods: {
    ...mapActions([
        'fetchTeam',
        'fetchCurrentPage',
    ]),
    setCurrentPage(val) {
        if(val <= this.getTeamListProps.totalPages && val > 0) {
            this.currentPage = val
        }
    },
    scrollTo(ref) {
      window.scrollTo({
        top: ref.offsetTop - 100,
        left: 0,
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.team {
  margin-top: 86px;
  padding: 0 .8rem;
  &__content {
    max-width: 1140px;
    margin: auto;
    padding:  0 .8rem;
  }
  &__sectionhead {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-family:"neue-plak";
    font-weight: 500;
    color: #182952;
    margin: 0 0 65px 0;
  }
  &__note {
    font-size: 15px;
    font-family: 'freight-text-pro';
    margin-left: .4rem;
    color: #666666;
    text-decoration: none;
  }
  &__section-bg {
      padding:30px 0;
      background-color: #e8edf8;
      position: relative;
      margin-bottom: 1.6rem;
      &:after {
          content: '';
          position: absolute;
          width: 100vw;
          left: calc(50% - 50vw);
          top: 0;
          z-index: -1;
          height: 100%;
          background-color: #e8edf8;
      }
  }
}
</style>
